import {React, useState} from 'react'

import NewProjects from "../components/newprojects"
import Blogs from '../components/blogs';
//import Reels from '../components/reels';
import ArtProjects from '../components/artprojects'
import About from '../components/about'

function ViewBar() {
    const [index, setIndex] = useState(1);

    function RenderComponent() {
        switch(index) {
            case 0:
                return <About></About>
            case 1:
                return <NewProjects></NewProjects>;
            case 2:
                return <Blogs></Blogs>;
            case 3:
                return <ArtProjects></ArtProjects>
            default:
                return null;
        }
    };

    function CustomButton({ idx, text })
    {
        return (
            <div className="flex-1 sm:w-auto w-full text-center">
                <button onClick={() => setIndex(idx)} className="px-8 sm:text-2xl text-xl text-black hover:text-opacity-30 duration-200">
                    {text}
                </button>
            </div>
        )
    }

    return (
        <div className="">
            <div className="buttonBar divide-x-2 flex flex-wrap justify-evenly items-center w-full sm:flex-row flex-row">
                <CustomButton idx={0} text={'About'}/>
                <CustomButton idx={1} text={'Projects'}/>
                <CustomButton idx={3} text={'Games/Art'}/>
                <CustomButton idx={2} text={'Blogs'}/>
                <div className="flex-1 sm:w-auto w-full text-center">
                    <a href="/resumes/GE_CG_Resume_Sep.pdf">
                        <span className="px-4 sm:text-2xl text-xl
                        text-black hover:text-opacity-30 duration-200">
                        Resume
                        </span>
                    </a>
                </div>
            </div>
            <div className=" w-full h-px mx-auto my-4 bg-black"></div>
            <div className="mt-4 ">
                <RenderComponent></RenderComponent>
            </div>
        </div>
    )
}

export default ViewBar;