import { React } from 'react';
import ViewBar from "../components/viewBar";
import NavBar from '../components/navbar';

function Homepage() {
    return (
        <div className="App">
            <NavBar/>
            <div className="description md:pl-16 md:py-2 px-8 pt-2 pb-8 sm:px-[10%] lg:px-[20%]">
                <div className="md:flex md:justify-between items-center">
                    <div id="intro" className="py-6">
                        <h1>Welcome, I'm Anthony</h1>
                    </div>
                    <div id="intro">
                    </div>
                </div>
                <div className="details lg:flex w-full">
                    <div className="Projects w-full" id="intro">
                        <ViewBar></ViewBar>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Homepage;