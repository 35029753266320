import React from 'react';

function Footer() {
    return (
        <div className='text-center bg-slate-100 py-6 italic text-sm'>
            Anthony Ge 2024
        </div>
    )
}

export default Footer;