import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from '../src/pages/homepage';
import OceanSim from '../src/pages/projects/tessendorfOcean';
import NightOwl from '../src/pages/projects/nightowl';
import Blog from '../src/pages/blog';

const Main = () => {
  return (
  <Router>
    <Routes>
      <Route exact path='/' element={<Home/>}></Route>
      <Route exact path='/tessendorfSim' element={<OceanSim/>}></Route>
      <Route exact path='/nightowl' element={<NightOwl/>}></Route>
      <Route exact path='/blog/:id' element={<Blog/>}></Route>
    </Routes>
  </Router>
  );
}

export default Main;