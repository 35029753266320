import { React } from 'react';
import NavBar from '../../components/navbar';
import Footer from '../../components/footer';

import oceanGif from '../../images/gifs/wooter.gif'
import oceanSim from '../../images/ocean/oceanSim.png'
import dftwaves from '../../images/ocean/dftwaves.png'
import choppywaves from '../../images/ocean/choppywaves.png'
import gatoradewater from '../../images/ocean/gatoradewater.png'

import fftVideo from '../../images/ocean/oceanFFT.mp4'
import awesomeWater from '../../images/ocean/awesomeWater2.mp4'

function OceanSim() {
    return (
        <div className="App">
            <NavBar/>
            <div className="description px-[10%] sm:px-[20%] py-4 " id="intro">
                <div className="">
                    <div className="title pb-2">
                        <h2 className='text-3xl'>Tessendorf Ocean Simulation</h2>
                        <p className=''>Unity, ShaderLab : March 2024</p>
                    </div>
                    <div className="image-box w-full py-4 text-center">
                        <img src={oceanSim} alt="water" className="aspect-video max-h-[22rem] mx-auto object-contain"></img>
                        <span className="italic py-2 opacity-60 text-sm">Still screenshot of my simulation.</span>
                    </div>
                    <div className="content leading-8">
                        <span>
                            WIP article on my ocean simulation project. It's inspired by a Jerry Tessendorf's 2001 paper&nbsp;
                        </span>
                        <a className="italic text-bold" href="https://www.researchgate.net/publication/264839743_Simulating_Ocean_Water">
                            Simulating Ocean Water
                        </a>
                        <span>, and I worked on this a lot from December 2023 to the middle of February of this year. Over time I will add more and more details 
                            to this page, but for now, here's a brief overview of what I did.
                        </span>
                        <br></br>
                        <br></br>
                        <span>The inspiration mostly came from&nbsp;
                        </span>
                        <a className="italic text-bold" href="https://youtu.be/yPfagLeUa7k?si=dSW2AUEvwPF4OEN2">
                            Acerola's work on ocean simulation
                        </a>
                        <span>, in which he originally started off with a FBM sine-wave approach to generate pretty looking waves. The problem, though, was that 
                            it was incredibly slow if you wanted to generate more and more waves. To solve this, he used an entirely different approach using FFTs.
                        </span>
                        <br></br>
                        <br></br>
                        <span>I actually fiddled with the first approach in my Mini-minecraft project -- pay attention to the ocean waves in that game! </span>
                        <br></br>
                        <br></br>
                        <span>Anyways, I began
                            with applying the Phillip's spectrum as described in Tessendorf's paper, and later on trying out a DFT to see if my anything I had done made sense.
                        </span>
                    </div>
                    <div className="image-box w-full py-4 text-center">
                        <img src={dftwaves} alt="water" className=" aspect-video max-h-[22rem] mx-auto object-contain"></img>
                        <span className="italic py-2 opacity-60 text-sm">Performance was not so great -- I think this was using a 128x128 spectrum?</span>
                    </div>
                    <div className="content leading-8">
                        <span>
                            I figuered at the time that whatever I had was good enough. From that point, I spent about a week wrapping my head around the 
                            Cooley-Tukey algorithm and how I would implement it with compute shaders in Unity.
                        </span>
                        <br></br>
                        <br></br>
                        <span>
                            Up until this point, pretty much everything was done with a compute shader, so I spent a lot of time trying to figure out how to implement
                            the FFT algorithm in the GPU, reading papers and articles on the internet. I eventually just used GasGiant's and Acerola's work as a reference, and I was able to get it working.
                        </span>
                    </div>
                    <div className="image-box w-full py-4 text-center">
                        <video controls>
                            <source src={fftVideo} type="video/mp4" className="aspect-video h-[10rem] mx-auto w-full"></source>
                        </video>
                        <span className="italic py-2 opacity-60 text-sm">Frames are pretty good! Recorded with my laptop's RTX 4070.</span>
                    </div>
                    <div className="image-box w-full py-4 text-center">
                        <img src={choppywaves} alt="water" className=" aspect-video max-h-[22rem] mx-auto object-contain"></img>
                        <span className="italic py-2 opacity-60 text-sm">After some time, here was how my ocean looked. Very choppy for 256x256 waves?</span>
                    </div>
                    <div className="image-box w-full py-4 text-center">
                        <img src={gatoradewater} alt="water" className=" aspect-video max-h-[22rem] mx-auto object-contain"></img>
                        <span className="italic py-2 opacity-60 text-sm">Tweaked the colors and heights a bit.</span>
                    </div>
                    <div className="content leading-8">
                        <span>
                            Eventually everything figured itself right, and I after applying some good specular highlights, fresnel, and other quick
                            real-time tricks to make the water look nice, I was able to render 512x512 waves at a decent frame rate!
                        </span>
                    </div>
                    <div className="image-box w-full py-4 text-center">
                        <video controls>
                            <source src={awesomeWater} type="video/mp4" className="aspect-video h-[10rem] mx-auto w-full"></source>
                        </video>
                        <span className="italic py-2 opacity-60 text-sm">The finished product...</span>
                    </div>
                    <div className="content leading-8">
                        <span>
                            I'm not done with the project yet, though! I'm hoping to add foam next, and upgrade the water to be more physically acccurate.
                            I intend to do this using some techniques i'm learning in CIS 5610, a computer graphics class I'm taking this semester, but until
                            then, I'm pretty happy with what I've done so far.
                        </span>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default OceanSim;