import {React} from 'react'
import CompactProject from "../components/compactProject";

import minecraftThumbnail from '../images/minecraft/Thumbnail2.png'
//import oceanThumb from '../images/thumbnails/oceanSim.png'
import oceanThumb from '../images/ocean/oceanWaves.png'
import vhs from '../images/thumbnails/retroScreen.png'
import snakeScreenShot from '../images/thumbnails/SnakeScreenShot.png'
import glassSnaile from '../images/thumbnails/glassSnaile2.png'
import ngen from '../images/ngen/JadeMario.png'
import valixrGlobe from '../images/valixr/valixrGlobe.png'
import flatResult from '../images/valixr/flatResult.png'

import infiniMirror from '../images/ptracer/infiniMirror.png'
import lifeRoblox from '../images/ptracer/life_is_roblox.jpg'
import boxEnv from '../images/ptracer/boxEnv.png'

// gifs
import oceanGif from '../images/gifs/theoceanblue.gif'
import retroFootage from '../images/retroVHS/retroOptimized.gif'
import snailBottle from '../images/gifs/snailBottle.gif'
import snakeGif from '../images/gifs/snakeGif.gif'
import fireball from '../images/gifs/fireball.gif'
import takeMeOut from '../images/gifs/takeMeOut.gif'

function NewProjects() {
    return (
        <div>
            <div className="md:inline-grid md:grid-cols-2 w-full items-center">
                <CompactProject 
                    imageList={[oceanGif]}
                    title="OceanFFT"
                    header="Unity, ShaderLab, C#"
                    link="/blog/FFTOceans">
                </CompactProject>
                <CompactProject
                    imageList={[valixrGlobe, flatResult]}
                    title="VALIXR @NASA: VR Real-Time Volume Rendering"
                    header="Unity, C#, HLSL/ShaderLab"
                    link="/files/GeVALIXRPoster24.pdf"
                    top={true}
                >
                </CompactProject>
                <CompactProject
                    imageList={[ngen]}
                    title="NGen: An OpenGL Deferred Renderer"
                    header="OpenGL, C++, GLSL"
                    link="/blog/SSAOandSSS">
                </CompactProject>
                <CompactProject 
                    imageList={[fireball]}
                    title="Supa Hot Fireball: Procedural Shader"
                    header="WebGL, GLSL"
                    link="https://github.com/geant04/supa-hot-fireball">
                </CompactProject>
                <CompactProject 
                    imageList={[takeMeOut]}
                    title="Rockin' Shaders: Stylized Unity Rendering"
                    header="Unity, HLSL, Shadergraph, C#"
                    fit={true}
                    link="https://github.com/geant04/hw04-stylization">
                </CompactProject>
                <CompactProject 
                    imageList={[minecraftThumbnail]} 
                    title="Mini Minecraft"
                    header="OpenGL, C++, GLSL"
                    link="https://vimeo.com/893391293">
                </CompactProject>
                <CompactProject 
                    imageList={[snailBottle]}
                    title="Liquid Bottle Shader"
                    header="Unity, HLSL/ShaderLab, C#"
                    fit={true}
                    link="https://youtu.be/19t-5yIyOek">
                </CompactProject>
                <CompactProject 
                    imageList={[retroFootage]}
                    title="RetroRender: VHS Post-Process Shader"
                    header="Unity, HLSL/ShaderLab, C#"
                    fit={true}
                    link="/blog/RetroRender">
                </CompactProject>
                <CompactProject 
                    imageList={[boxEnv, lifeRoblox, infiniMirror]} 
                    title="Monte Carlo Path Tracer"
                    header="OpenGL, C++, GLSL"
                    top={false}
                    fit={true}
                    link="">
                </CompactProject>
                {/* <CompactProject 
                    imageList={[snakeScreenShot, snakeGif]}
                    title="3D Stylized MGS Snake"
                    header="Unity ShaderGraph, HLSL"
                    link="https://github.com/geant04/yoji-shinkawa-style">
                </CompactProject> */}
            </div>
        </div>
    )
}

export default NewProjects;
