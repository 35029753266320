import { Link } from 'react-router-dom';
import oceanGif from '../images/gifs/theoceanblue.gif'
import CompactProject from "../components/compactProject";
import jwalker from '../images/jwalker/game.png'
import led from '../images/soccer/LED_Preview.png'
import ledGif from '../images/gifs/LCDGif.gif'

function Blogs()
{
    return (
        <div>
            <div>
            <div className="md:inline-grid md:grid-cols-2 w-full items-center">
                <CompactProject 
                    imageList={[ledGif, led]}
                    title="November Updates"
                    header="Fascinating Shadergraph discoveries with the voronoi node, and UPGRADE game jam shenanigans- 11/27/2024"
                    italic={true}
                    link="/blog/november24Update">
                </CompactProject>
            </div>
            <div className="p-4 mb-2 flex flex-col" id="projectFX">
                Other Blogs
                <div>
                    <Link to="/blog/SSAOandSSS" className="text-blue-700 font-semibold"> SSAO and SSS - 6/8/2024 </Link>
                </div>
                <Link to="/blog/deferredprogress" className="text-blue-700"> Deferred Progress - 5/25/2024 </Link>
                <Link to="/blog/goldensnail" className="text-blue-700"> Golden Snail - 5/25/2024 </Link>
                <Link to="/blog/first" className="text-blue-700"> The first post - 5/21/2024 </Link>
            </div>
            </div>
        </div>
    )
}

export default Blogs;