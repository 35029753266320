

import linkedin from '../images/linkedin.svg';
import github from '../images/github.svg';
import mail from '../images/mail.svg';

function About()
{
    return (
        <div>
            <div id="projectFX">
                <div className="mt-4">
                    I'm Anthony Ge, a student at the University of Pennsylvania majoring in computer graphics 
                    under the <b>Digital Media Design</b> program. I co-run <a href="https://pennupgrade.com/"><b>UPGRADE</b></a>
                    , Penn's premier game development club, and
                    also assist teaching CIS 4600/5600. I've previously interned <b>@NASA's Goddard Space Flight Center </b>
                    working on volume rendering applications for VALIXR.
                    <br></br>
                    <br></br>
                    I'm interested in real-time rendering and creative shader-writing for purposes in data visualization and games/simulation. 
                    Feel free to check out my projects and see what I work on. Thanks for visiting!
                </div>

                <div className="flex mt-4 py-4 space-x-10" id="">
                    <div className='flex space-x-2'>
                        <a href="https://www.linkedin.com/in/anthonyge/"><img src={linkedin} alt="linkedin" 
                            class="w-[3em] bg-white rounded-lg p-[2px] hover:opacity-50 duration-300"/></a>
                        <a href="https://www.github.com/geant04"><img src={github} alt="github" 
                            class="w-[3em] bg-white rounded-full p-[2px] hover:opacity-50 duration-300"/></a>
                        <a href="mailto::geant@seas.upenn.edu"><img src={mail} alt="mail" 
                            class="w-[3em] bg-white rounded-full p-[2px] hover:opacity-50 duration-300"/></a>
                    </div>
                </div>
        
                <div className=" w-full h-px mx-auto my-4 bg-black"></div>
                <div className="">
                    <h1>CG Demo Reel</h1>
                    <br></br>
                    <div className="projectFX bg-blue" id="projectFX">
                        <div className="md:w-[75%] lg:w-[65%]">
                            <iframe src="https://player.vimeo.com/video/899942080?h=effef4f596"
                            className="w-full aspect-video" frameborder="0" allow="autoplay; fullscreen" title="demo" allowfullscreen></iframe>
                        </div>
                    </div>
                    <br></br>
                </div>

                <div className="mb-8">
                    Learn more about my projects in motion + breakdowns!
                </div>

                <div className=" w-full h-px mx-auto my-4 bg-black"></div>
                <h1>More Info</h1>

                <div className="mt-4 mb-16">
                    Artstation: <a href="https://www.artstation.com/geant">  
                    <span class="w-[3em] bg-white rounded-full p-[2px] hover:opacity-50 duration-300">
                     https://www.artstation.com/geant
                    </span>
                    </a>
                </div>

            </div>
        </div>
    )
}

export default About;