import {React} from 'react'
import CompactProject from "../components/compactProject";
import crabRig from "../images/art/crab_rig.png"
import awesomeRoom from "../images/art/awesomestRoom.png"
import tankGif from "../images/art/tankGif.gif"
import deth from "../images/art/d2.webp"
import galaxy from "../images/art/galaxy.webp"
import stickfighters from "../images/stickfighters.gif" 
import jwalker from '../images/jwalker/game.png'
import findJason from '../images/findJason/findJasonGif.gif'

function ArtProjects() {
    return (
        <div>
            <h1 className="mt-8">Games</h1>
            <div className=" w-full h-px mx-auto my-4 bg-black"></div>
            <div className="md:inline-grid md:grid-cols-2 items-center w-full">
                <CompactProject 
                    imageList={[tankGif]}
                    title="Couverture (2024, WIP)"
                    header="Single player action-adventure game. Team project - responsible for player controls, level + game design, and shaders. Built in  Unity, C#, HLSL"
                    italic={true}
                    link="https://pennupgrade.itch.io/chetrico">
                </CompactProject>
                <CompactProject 
                    imageList={[findJason]}
                    title="Find Jason (2024, WIP)"
                    header="Surveillance-type game. Individually programmed procedural NPC generation, AI, and crowd placement. Built in  Unity, C#, HLSL"
                    italic={true}
                    link="https://github.com/geant04/final-project/tree/main">
                </CompactProject>
                <CompactProject 
                    imageList={[jwalker]}
                    title="J.Walker Rally 2K25 | 24 Hour Madness"
                    header="Procedural game jam survival game! Responsible for procedural terrain generation, crowd spawning, NPC shader. Built in Unity, C#"
                    italic={true}
                    link="https://aczw.itch.io/jwalker-rally-2k25">
                </CompactProject>
                <CompactProject 
                    imageList={[deth]}
                    title="Deth (2023)"
                    header="Unity, C#"
                    link="https://pennupgrade.com/games/deth">
                </CompactProject>
                <CompactProject 
                    imageList={[stickfighters]}
                    title="Stickfighters (2022)"
                    header="Java, LibGDX"
                    top={false}
                    link="https://github.com/geant04/stickfighters">
                </CompactProject>
                <CompactProject 
                    imageList={[galaxy]}
                    title="Galaxy Gacha (2022)"
                    header="Unity, C#"
                    link="https://pennupgrade.com/games/galaxy-gacha">
                </CompactProject>
            </div>
            
            <h1 className="mt-6">Art</h1>
            <div className=" w-full h-px mx-auto my-4 bg-black"></div>
            
            <div className="my-4 text-1xl">
                    Check out my artstation here: <a href="https://www.artstation.com/geant">  
                    <span class="w-[3em] bg-white rounded-full p-[2px] hover:opacity-50 duration-300">
                     https://www.artstation.com/geant!
                    </span>
                    </a>
                </div>
            <div className="md:inline-grid md:grid-cols-2 items-center w-full">
                <CompactProject 
                    imageList={[crabRig]}
                    title="Crab Rig (2024)"
                    header="Unreal Engine 5, Maya"
                    fit={true}
                    link="">
                </CompactProject>
                <CompactProject 
                    imageList={[awesomeRoom]}
                    title="Lock In! (2023)"
                    header="Maya"
                    fit={true}
                    link="">
                </CompactProject>
            </div>
            <div className="mb-16"></div>
        </div>
    )
}

export default ArtProjects;
