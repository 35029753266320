import { React, useEffect, useState } from 'react';
import NavBar from '../components/navbar';
import Footer from '../components/footer';

import { useParams } from 'react-router-dom';
import Markdown from "react-markdown";
import rehypeRaw from 'rehype-raw';

function Blog() {
    const { id } = useParams();
    const [markdown, setMarkdown] = useState("");
    const [error, setError] = useState(null);

    // get blog from ../pages/blog/[id].md?
    useEffect(() => {
        try {
            const file = require(`../blogfiles/${id}.md`);
            fetch(file)
            .then((res) => res.text())
            .then((text) => {
                console.log(text);
                setMarkdown(text)}
            );
        } catch (e) {
            setError(e);
        }
    }, []);

    if (error) {
        return (
            <div>
                woops there's no blog here
            </div>
        )
    }

    return (
        <div className="App flex flex-col min-h-screen">
            <NavBar/>
            {error && <div>{error}</div>}
            <div className="flex-grow">
                <div className="description px-[10%] sm:px-[20%] py-4 " id="intro">
                    <Markdown className="markdown" rehypePlugins={[rehypeRaw]}>{markdown}</Markdown>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Blog;