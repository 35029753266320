import React from 'react';
import { Link } from 'react-router-dom';

function NavBar() {

    const phraseArray = [
        "B)",
        "(O_o)",
        "._.",
        ":}",
        "@_@",
        ":)"
    ];
    const randNum = Math.floor(Math.random() * phraseArray.length);
    const randPhrase = phraseArray[randNum];

    return (
        <div className="bg-slate-300">
            <div className="w-full h-20 p-4 flex px-[10%] lg:px-[20%] justify-between"  id="intro2">
                <div className="flex items-center">
                    <Link to="/" className=" text-4xl font-semibold hover:opacity-40 duration-200 mb-2">geant</Link>
                    <div className="mt-2 ml-3 italic" id="splash">{randPhrase}</div>
                </div>
                <div id="" className="flex items-center space-x-3">
                    <Link to="/" className="font-semibold py-2 text-2xl hover:opacity-40 duration-200">Home</Link>
                </div>
            </div>
        </div>
    )
}

export default NavBar;